import { useRef } from "react";
import { useGetWatchlistUnderlyingsStateMutation } from "src/api/client/watchlist.api";
import { EnumTaggedEntityType } from "src/components/feed-card/types/enums";
import { IFeedCard } from "src/components/feed-card/types/feed-card";
import { useUnderlyingFollowStore } from "src/stores/uderlying-follow-store";
import { removeDuplicates } from "src/utils/array-util";

export const usePopulateFeedFollowStores = (feed: ReadonlyArray<IFeedCard> | undefined) => {
    const { setCanBeFollowedUnderlying, setIsFollowedUnderlying, canBeFollowedUnderlyingIsins } = useUnderlyingFollowStore();
    const { mutate: mutateWatchlistUnderlyingsState } = useGetWatchlistUnderlyingsStateMutation();

    const refWatchlist = useRef(new Set());

    const underlyingIsins = feed?.flatMap(
        item =>
            item.taggedEntities?.flatMap(entity =>
                entity.entityType === EnumTaggedEntityType.Underlying && !canBeFollowedUnderlyingIsins.includes(entity.entityId)
                    ? [entity.entityId]
                    : []
            ) ?? []
    );

    const uniqueUnderlyingIsins = underlyingIsins
        ? removeDuplicates(underlyingIsins).filter(underlying => ![...refWatchlist.current].includes(underlying))
        : [];

    if (uniqueUnderlyingIsins.length) {
        uniqueUnderlyingIsins.forEach(underlying => refWatchlist.current.add(underlying));

        mutateWatchlistUnderlyingsState(
            {
                underlyingIsins: uniqueUnderlyingIsins,
            },
            {
                onSuccess: data => {
                    if (!data) {
                        return;
                    }

                    Object.entries(data).forEach(([underlyingIsin, { canBeFollowed, isFollowed }]) => {
                        setCanBeFollowedUnderlying(underlyingIsin, canBeFollowed);
                        setIsFollowedUnderlying(underlyingIsin, isFollowed);
                    });
                },
            }
        );
    }
};
