import { IFeedResponse } from "src/api/client/feed.api.types";
import { useInfiniteQuery } from "src/hooks/react-query/use-infinite-query";
import { EnumFeedType } from "../server/feed.types";

export interface IFeedInfiniteQueryParams {
    feedUrl: string;
    enabled: boolean;
    sessionId?: string;
    feedType?: EnumFeedType;
    wikifolioId?: string;
}

export const useFeedInfiniteQuery = ({ feedUrl, sessionId, feedType, wikifolioId, enabled }: IFeedInfiniteQueryParams) => {
    const queryParams = new URLSearchParams();
    if (sessionId) {
        queryParams.append("sessionId", sessionId);
    }

    if (feedType) {
        queryParams.append("feedType", feedType.toString());
    }

    if (wikifolioId) {
        queryParams.append("wikifolioId", wikifolioId);
    }

    return useInfiniteQuery<IFeedResponse>(
        feedUrl,
        {
            getNextPageParam: lastPage => {
                return lastPage.data.next ? lastPage.data.pageNumber + 1 : undefined;
            },
            queryParams,
            refetchOnWindowFocus: false,
            enabled,
        },
        undefined,
        feedType ? [feedUrl, feedType] : undefined
    );
};
