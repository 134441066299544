import { useTranslation } from "next-i18next";
import { useCommentReportMutation } from "src/api/client/comment-report";
import { EnumToastStatus, useWfToast } from "src/hooks/use-wf-toast";

interface ICommentReport {
    onSuccess: () => void;
    onError: () => void;
}

export const useCommentReport = ({ onSuccess, onError }: ICommentReport) => {
    const { t } = useTranslation("feed");
    const toast = useWfToast();

    const { mutatePromise, isLoading } = useCommentReportMutation({
        onSuccess: () => {
            onSuccess();
            toast({ status: EnumToastStatus.Success, title: t("toast.comment-reported-success") });
        },
        onError: () => {
            onError();
            toast({ status: EnumToastStatus.Error, title: t("toast.comment-reported-error") });
        },
    });

    return {
        commentReport: mutatePromise,
        isLoading,
    };
};
