import { useEffect, useMemo, useState } from "react";
import { IFeedInfiniteQueryParams, useFeedInfiniteQuery } from "src/api/client/feed.api";
import { IFeedCard } from "src/components/feed-card/types/feed-card";
import { usePopulateFeedFollowStores } from "src/components/pages/feed/hooks/use-populate-feed-follow-stores";
import { isServerSide } from "src/utils/common-util";

export const useInfiniteFeed = ({ feedUrl, feedType, wikifolioId, enabled }: IFeedInfiniteQueryParams) => {
    const [sessionId, setSessionId] = useState<string>();
    const [loadingTriggerElement, setLoadingTriggerElement] = useState<HTMLElement | null>(null);
    const { isLoading, isFetchingNextPage, isError, data, fetchNextPage, hasNextPage, refetch } = useFeedInfiniteQuery({
        feedUrl,
        sessionId,
        feedType,
        wikifolioId,
        enabled,
    });

    const feed = useMemo(() => {
        if (!isLoading && !isError && data) {
            return data.pages.flatMap(item =>
                item.data.results.map(
                    feedCard =>
                        ({
                            ...feedCard,
                            userReactionContentReferences: feedCard.userReactionContentReferences || [
                                {
                                    userReactionContentId: feedCard.userActivityEntity?.id,
                                    userReactionContentType: feedCard.userActivityEntity?.type,
                                },
                            ],
                        } as IFeedCard)
                )
            );
        }
    }, [data, isLoading, isError]);

    const intersectionObserver = useMemo(() => {
        if (!isServerSide()) {
            return new IntersectionObserver(
                entries => {
                    if (entries && entries[0].isIntersecting) {
                        fetchNextPage();
                    }
                },
                { threshold: 0.1 }
            );
        }
    }, [fetchNextPage]);

    useEffect(() => {
        if (loadingTriggerElement && intersectionObserver) {
            intersectionObserver.observe(loadingTriggerElement);
        }

        return () => {
            if (loadingTriggerElement && intersectionObserver) {
                intersectionObserver.unobserve(loadingTriggerElement);
            }
        };
    }, [intersectionObserver, loadingTriggerElement]);

    useEffect(() => {
        if (intersectionObserver && loadingTriggerElement && !hasNextPage) {
            intersectionObserver.unobserve(loadingTriggerElement);
        }
    }, [hasNextPage, intersectionObserver, loadingTriggerElement]);

    useEffect(() => {
        if (!sessionId && data?.pages?.[0]?.data.sessionId) {
            setSessionId(data.pages[0].data.sessionId);
        }
    }, [data, sessionId]);

    usePopulateFeedFollowStores(feed);

    return {
        feed,
        feedType,
        isLoading,
        isFetchingNextPage,
        isError,
        hasNextPage,
        refetch,
        fetchNextPage,
        setLoadingTriggerElement,
    };
};
