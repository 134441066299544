import { ChangeEventHandler, FormEvent, useRef } from "react";
import {
    Flex,
    FormControl,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Textarea,
    useBoolean,
    useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button } from "src/components/base/button";
import { WfModalCloseButton } from "src/components/base/wf-modal-close-button";
import { RichText } from "src/components/common/rich-text/rich-text";
import { useCommentReport } from "src/components/feed-card/hooks/use-comment-report";
import { useReportModalStore } from "src/components/feed-card/store/report-modal-store";
import { EnumToastStatus, useWfToast } from "src/hooks/use-wf-toast";

interface IFeedReportModalProps {
    commentReportDescription?: string;
}

const MIN_TEXT_LENGTH = 3;
const MAX_CHARACTERS = 1000;

export const FeedReportModal = (props: IFeedReportModalProps) => {
    const { t } = useTranslation("feed");
    const store = useReportModalStore();
    const toast = useWfToast();
    const modalProps = useDisclosure();
    const textRef = useRef("");
    const [disableSubmit, setDisableSubmit] = useBoolean(true);

    const onClose = () => {
        setDisableSubmit.on();
        textRef.current = "";
        store.setIsOpen(false);
    };
    const { commentReport, isLoading } = useCommentReport({ onSuccess: onClose, onError: onClose });

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
        const text = event.target.value.trim();

        if (text.length >= MIN_TEXT_LENGTH) {
            setDisableSubmit.off();
        } else {
            setDisableSubmit.on();
        }

        textRef.current = text;
    };

    const onSubmit = (event: FormEvent<EventTarget>) => {
        event.preventDefault();

        if (textRef.current.length > MAX_CHARACTERS) {
            toast({
                status: EnumToastStatus.Error,
                title: t("toast.report-character-count-exceeded-limit", { 0: textRef.current.length - MAX_CHARACTERS }),
            });
        } else {
            commentReport({
                commentId: store.entityId,
                reportMessage: textRef.current,
            });
        }
    };

    return (
        <Modal {...modalProps} isOpen={store.isOpen}>
            <ModalOverlay />

            <ModalContent maxW={["full", "688px", "708px", "708px"]}>
                <ModalHeader pb={1}>{t("headline.report-comment")}</ModalHeader>
                <WfModalCloseButton onClick={onClose} />
                <ModalBody pb={5}>
                    <RichText mb={[2, 3]} fontSize="md" text={props.commentReportDescription ?? ""} />
                    <Stack spacing={3}>
                        <FormControl as="form" onSubmit={onSubmit} onInvalid={evt => evt.preventDefault()}>
                            <Textarea onChange={handleChange} size="lg" placeholder={t("placeholder.reason-for-report")} />
                            <Flex pt={2} justifyContent="end">
                                <Button type="submit" isLoading={isLoading} isDisabled={disableSubmit}>
                                    {t("buttons.report")}
                                </Button>
                            </Flex>
                        </FormControl>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
