import { UseMutationOptions } from "react-query";
import { API_COMMENT_V2_URL } from "src/api/constants/comment-v2-urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { IErrorResponse } from "./types";

interface ICommentReportParams {
    commentId: string;
    reportMessage: string;
}

interface IResponse {
    isSuccess: boolean;
}

export function useCommentReportMutation(options: UseMutationOptions<IResponse, IErrorResponse, ICommentReportParams>) {
    return useMutation<IResponse, IErrorResponse, ICommentReportParams>(`${API_COMMENT_V2_URL}/report`, options);
}
