import { FC } from "react";
import { Skeleton, SkeletonCircle, SkeletonText, HStack, VStack } from "@chakra-ui/react";
import { FeedCardLayout } from "src/components/feed-card/common/feed-card-layout";

const FEED_CARD_SKELETON_TEST_ID = "feed-card-skeleton";

export const FeedCardSkeleton: FC = () => (
    <FeedCardLayout.Base data-test-id={FEED_CARD_SKELETON_TEST_ID}>
        <FeedCardLayout.Header>
            <Skeleton h={1} w={10} />
        </FeedCardLayout.Header>
        <FeedCardLayout.Content>
            <HStack spacing={1} align="flex-start">
                <SkeletonCircle size="6" />
                <VStack spacing="12px" align="flex-start">
                    <Skeleton h={2} w={15} />
                    <Skeleton h={1} w={5} />
                </VStack>
            </HStack>
            <SkeletonText w="100%" mt={2} noOfLines={4} spacing={2} skeletonHeight="12px" />
        </FeedCardLayout.Content>
        <FeedCardLayout.Footer>
            <Skeleton rounded="8px" my={1} h={3} w="100px" />
        </FeedCardLayout.Footer>
    </FeedCardLayout.Base>
);
